<template>
  <div class="ebook">
    <div class="ebook__container container container--big">
      <div class="ebook__image--mobile">
        <img
          src="/assets/images/ebook-mobile.png"
          alt="Edunails ebook"
          class="ebook__image-inner--mobile"
        />
        <div class="ebook__image-background ebook__image-background--mobile" />
      </div>
      <section class="ebook__side">
        <h1 class="ebook__heading">
          {{ $t("ebookPage.heading[0]") }}
          <strong class="ebook__heading-strong">
            {{ $t("ebookPage.heading[1]") }}
          </strong>
          {{ $t("ebookPage.heading[2]") }}
        </h1>
        <div class="ebook__content">
          <h2 class="ebook__sub-heading">{{ $t("ebookPage.subHeading") }}</h2>
          <p class="ebook__paragraph">{{ $t("ebookPage.paragraph") }}</p>
          <p class="ebook__paragraph ebook__exclusive">
            {{ $t("ebookPage.exclusive") }}
          </p>
          <base-card class="ebook__card">
            <h3 class="ebook__form-heading">
              {{ $t("signIn.section.subTitle") }}
            </h3>
            <sign-up-form
              v-bind="$t(`signUp.formFields`)"
              class="ebook__form"
              centerButton
            />
          </base-card>
          <p class="ebook__paragraph">{{ $t("ebookPage.joinUs") }}</p>
          <p class="ebook__paragraph ebook__socials">
            {{ $t("ebookPage.follow") }}
            <a
              class="ebook__socials-link"
              title="Facebook"
              href="https://www.facebook.com/Edunailsofficial-101086191589499/"
            >
              <img
                class="ebook__socials-icon"
                alt=""
                :src="`/assets/socials/fb.svg`"
              />
            </a>
            <a
              class="ebook__socials-link"
              title="Instagram"
              href="https://www.instagram.com/edunails.official/"
            >
              <img
                class="ebook__socials-icon"
                alt=""
                :src="`/assets/socials/ig.svg`"
              />
            </a>
          </p>
        </div>
      </section>
    </div>
    <div class="ebook__image">
      <img
        src="/assets/images/ebook.png"
        alt="Edunails ebook"
        class="ebook__image-inner"
      />
      <div class="ebook__image-background" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ebook',
  data: () => ({}),
  methods: {},
};
</script>
<style lang="scss" scoped src="./Ebook.scss" />
